import styled from "styled-components";
import { PRIMARY_COLOR } from "../../constants";

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainPageWraper = styled.div`
  display: flex;
  background: ${PRIMARY_COLOR};
`;

export const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .info-box {
    display: contents;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const NavigationStyle = styled.div`
  min-width: 200px;
  height: 100vh;
  background: ${PRIMARY_COLOR};
  text-align: center;
  @media (max-width: 1024px) {
    min-width: 300px;
  }
`;
export const LogoNav = styled.img`
  width: 100px;
  object-fit: contain;
  margin-bottom: 10px;
  @media (max-width: 590px) {
    width: 120px;
  }
`;
export const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  padding: 10px 16px;
  opacity: 0.4;
  cursor: pointer;
  align-items: center;
  transition: opacity 1s;
  &:hover {
    opacity: 1;
    background: #154d2c;
  }
`;
export const ItemLabel = styled.span`
  font-size: 16px;
  margin-left: 10px;
`;
export const ContentWraper = styled.div`
  padding: 24px;
  flex: 1;
  flex-direction: column;
  display: flex;
  text-align: left;
  background: #e5e5e5;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }
  @media (max-width: 590px) {
    width: 100%;
    padding: 0;
  }
`;
export const FilterWraper = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  display: block;
  margin: 10px 0;
  padding: 16px;
  background: #fff;
  .icon-arrown-down-detail-active {
    display: block;
    cursor: pointer;
  }
  .icon-arrown-down-detail {
    display: block;
    cursor: pointer;
  }
  .icon-arrown-down-detail-active {
    transform: rotate(90deg);
    transition: transform 0.2s ease-out;
    display: block;
  }
  .icon-arrown-down-detail {
    display: block;
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }

  .info-box-active {
    max-height: 100vh;
    transition: max-height 0.3s ease-out;
  }

  .info-box {
    display: inline-grid;
    max-height: 1px;
    transition: max-height 0.3s ease-in;
    .MuiGrid-container {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    margin: 5px;
    margin-bottom: 10px;
    .icon-arrown-down-detail-active {
      transform: rotate(90deg);
      transition: transform 0.2s ease-out;
      display: block;
    }
    .icon-arrown-down-detail {
      display: block;
      transform: rotate(0deg);
      transition: transform 0.2s ease-out;
    }
    .info-box-active {
      max-height: 100vh;
      transition: max-height 0.3s ease-out;
      .MuiGrid-container {
        display: flex;
      }
    }

    .info-box {
      display: inline-grid;
      max-height: 1px;
      transition: max-height 0.3s ease-in;
      .MuiGrid-container {
        display: none;
      }
    }
  }
`;
export const ComponentWraper = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
  padding: 16px;
  background: #fff;
  .MuiFormLabel-root.Mui-disabled {
    color: #176939 !important;
  }
  .none-disable {
    color: black;
  }
  .icon-arrown-down-detail-active {
    display: block;
    cursor: pointer;
  }
  .icon-arrown-down-detail {
    display: block;
    cursor: pointer;
  }

  .icon-arrown-down-detail-active {
    transform: rotate(90deg);
    transition: transform 0.2s ease-out;
    display: block;
  }
  .icon-arrown-down-detail {
    display: block;
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }

  .info-box-receive-active {
    max-height: 100vh;
    display: flex;
    transition: max-height 0.3s ease-out;
    .MuiGrid-container {
      display: flex;
    }
  }

  .info-box-receive {
    max-height: 1px;
    transition: max-height 0.3s ease-in;
    .MuiGrid-container {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    margin: 5px;
    .icon-arrown-down-detail-active {
      transform: rotate(90deg);
      transition: transform 0.2s ease-out;
      display: block;
    }
    .icon-arrown-down-detail {
      display: block;
      transform: rotate(0deg);
      transition: transform 0.2s ease-out;
    }
    .info-box-receive-active {
      max-height: 100vh;
      display: flex;
      transition: max-height 0.3s ease-out;
      .MuiGrid-container {
        display: flex;
      }
    }

    .info-box-receive {
      max-height: 1px;
      transition: max-height 0.3s ease-in;
      .MuiGrid-container {
        display: none;
      }
    }
  }
`;
export const TitleStyle = styled.div`
  color: ${PRIMARY_COLOR};
  font-size: 20px;
  font-weight: bold;
`;
export const TitleComponent = styled.div`
  color: ${PRIMARY_COLOR};
  font-size: 18px;
  flex: 1;
  font-weight: bold;
`;

export const TableWraper = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
  display: grid;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
  padding-top: 10px;
  background: #fff;
  .item-order-detail {
    margin-bottom: 1px;
  }
  .icon-arrown-down-detail-active {
    transform: rotate(90deg);
    transition: transform 0.2s ease-out;
    display: block;
  }
  .ca-dika: {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .fSLtIR {
    flex: 1;
    display: flex;
  }
  .icon-arrown-down-detail {
    cursor: pointer;
    display: block;
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }
  .icon-arrown-down-detail-active {
    transform: rotate(90deg);
    transition: transform 0.2s ease-out;
    display: block;
    cursor: pointer;
  }
  .info-box-active .MuiTableContainer-root {
    max-height: 100vh;
    transition: max-height 0.3s ease-out;
    .MuiGrid-container {
      display: block;
    }
  }

  .info-box .MuiTableContainer-root {
    max-height: 1px;
    transition: max-height 0.3s ease-in;
    .MuiGrid-container {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    margin: 5px;
    .icon-arrown-down-detail-active {
      transform: rotate(90deg);
      transition: transform 0.2s ease-out;
      display: block;
    }
    .ca-dika: {
      display: flex;
      flex: 1;
      align-items: center;
    }
    .fSLtIR {
      flex: 1;
      display: flex;
    }
    .icon-arrown-down-detail {
      display: block;
      transform: rotate(0deg);
      transition: transform 0.2s ease-out;
    }
    .info-box-active .MuiTableContainer-root {
      max-height: 100vh;
      transition: max-height 0.3s ease-out;
      .MuiGrid-container {
        display: block;
      }
    }

    .info-box .MuiTableContainer-root {
      max-height: 1px;
      transition: max-height 0.3s ease-in;
      .MuiGrid-container {
        display: none;
      }
    }
  }
`;

export const TitleProduct = styled.div`
  color: ${PRIMARY_COLOR};
  font-size: 18px;
  margin-right: 10px;
  font-weight: bold;
`;

export const DivRowTitleDetail = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: rơw;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AppBar = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 16px;
`;
export const AppBarTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

export const PaymentStyle = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: space-between;
`;
