import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";

const propTypes = {};

const LoaderFullScreen = ({ open = false }) => {
  //! State

  //! Function
  useEffect(() => {
    if (open) {
      document.body.classList.add("lock-body");
    } else {
      document.body.classList.remove("lock-body");
    }

    return () => {
      document.body.classList.remove("lock-body");
    };
  }, [open]);

  //! Render
  return (
    <div className={`loader-full-screen ${open ? "show" : ""}`}>
      <CircularProgress />
    </div>
  );
};

LoaderFullScreen.propTypes = propTypes;
export default LoaderFullScreen;
