import { API_EXPORT_PRE_ORDER, API_SCHEDULE_ORDER_NOTI, API_SCHEDULE_ORDER_PUSH_NOTI } from "../constants/api";
import axios from "../utils/axios";

export const getListOrder = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`ts/api/plg/v1/seller/order/list`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getTotalOrder = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`ts/api/plg/v1/seller/order/total`, body)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getDetailOrder = (orderNo) => {
  let url = `ts/api/plg/v1/seller/order/${orderNo}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateDetailOrder = (body) => {
  let url = "ts/api/plg/v1/seller/order";
  return axios.put(url, body);
};

export function printOrder(input) {
  return new Promise((resolve, reject) => {
    axios
      .post("ts/api/plg/v1/seller/order/print", input)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getScheduleOrderNotification() {
  return axios.get(API_SCHEDULE_ORDER_NOTI);
}

export const pushScheduleOrderNotification = ({ orderCode, body }) => {
  const url = API_SCHEDULE_ORDER_PUSH_NOTI + "?orderCode=" + orderCode;
  return axios.post(url, body);
};
export const exportPreOrder = (body) => {
  return axios.post(API_EXPORT_PRE_ORDER, body, {
    responseType: "blob",
  });
};
