import LoaderFullScreen from "../components/LoaderFullScreen";
import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

const withLoadingScreen = (BaseComponent) => {
  return class ComponentWrapped extends Component {
    constructor(props) {
      super(props);
      this.props = props;
      this.state = {
        open: false,
      };

      this.showLoading = this.showLoading.bind(this);
      this.closeLoading = this.closeLoading.bind(this);
    }

    showLoading() {
      this.setState({ open: true });
    }

    closeLoading() {
      this.setState({ open: false });
    }

    render() {
      return (
        <Fragment>
          {this.state.open &&
            ReactDOM.createPortal(
              <LoaderFullScreen open={this.state.open} />,
              document.body
            )}

          <BaseComponent
            {...this.props}
            showLoading={this.showLoading}
            closeLoading={this.closeLoading}
          />
        </Fragment>
      );
    }
  };
};

export default withLoadingScreen;
