import axios from "axios";
import Cookies from "js-cookie";
import {
  LOCALSTORAGE_ACCESSS_TOKEN,
  LOCALSTORAGE_ISADMIN,
  LOCALSTORAGE_USERNAME,
} from "../constants";
import { FirebaseUltils } from "../firebase-ultils/firebase-ultils";
import { API_REFRESH_TOKEN } from "../services/apiConfig";
import { removeToken } from "../services/fcmServices";

class InstanceAxios {
  // protected axios;
  constructor() {
    this.axios = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      responseType: "json",
      headers: {
        Accept: "application/json",
        // Authorization: ""
      },
    });
    this.axios.interceptors.request.use(async (param) => {
      // console.log("Request ==>", param.url, param.data);
      const token = await localStorage.getItem(LOCALSTORAGE_ACCESSS_TOKEN);

      return {
        ...param,
        headers: {
          ...param.headers,
          common: {
            // "Cache-Control": "no-cache",
            ...param.headers.common,
            Authorization: `Bearer ${token}`,
          },
        },
        params: {
          t: new Date().getTime(),
        },
      };
    });

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.axios.interceptors.response.use(
      (response) => {
        // console.log(
        //   `status ==> ${response.status} --`,
        //   "response ===>",
        //   response.data,
        // );
        return response;
      },
      (error) => {
        const { config } = error;

        if (error?.response?.status === 401) {
          return this.updateToken().then((res) => {
            Cookies.set("access_token", `${res?.data?.accessToken}`);
            Cookies.set("refresh_token", `${res?.data?.accessToken}`);
            localStorage.setItem(
              LOCALSTORAGE_ACCESSS_TOKEN,
              res?.data?.accessToken,
            );
            config.headers.Authorization = `Bearer ${res?.data?.accessToken}`;
            this.attachTokenToHeader(res?.data?.accessToken);
            return axios.request(config);
          });
        }
        return error.response;
      },
    );
  }

  attachTokenToHeader(token) {
    this.axios.interceptors.request.use(function (config) {
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
  }

  // processInterceptorsResponse() {

  //   this.axios.interceptors.response.use(
  //     (response) => {
  //       return response;
  //     },
  //     (error) => {
  //       const { config, response: { status } } = error
  //       if (error?.response?.status === 401) {
  //         return this.updateToken().then((res) => {
  //           console.log(res?.data);
  //           Cookies.set('access_token', `${res?.data?.accessToken}`);
  //           Cookies.set('refresh_token', `${res?.data?.refreshToken}`);

  //           config.headers.Authorization = `Bearer ${res?.data?.refreshToken}`;
  //           //this.attachTokenToHeader(res?.data?.refreshToken);
  //           return axios.request(config);
  //         });

  //       }
  //       return error.response;
  //     }
  //   );
  // }
  updateToken() {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
    }
    const refreshToken = user.refreshToken || "";
    if (!refreshToken) {
      return new Promise((resolve, reject) => {
        this.axios
          .post(API_REFRESH_TOKEN, { refresh_token: refreshToken })
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      Cookies.remove("refresh_token");
      Cookies.remove("access_token");
      Cookies.remove("storeIds");
      removeToken();
      localStorage.removeItem("permission");
      localStorage.removeItem(LOCALSTORAGE_ACCESSS_TOKEN);
      localStorage.removeItem(LOCALSTORAGE_USERNAME);
      localStorage.removeItem(LOCALSTORAGE_ISADMIN);
      localStorage.removeItem("FIREBASE_TOKEN");
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
  }

  get(...arg) {
    return this.axios.get(...arg);
  }

  post(...arg) {
    return this.axios.post(...arg);
  }

  put(...arg) {
    return this.axios.put(...arg);
  }

  delete(...arg) {
    return this.axios.delete(...arg);
  }

  patch(...arg) {
    return this.axios.patch(...arg);
  }
}

export default new InstanceAxios();
