import { useCallback, useEffect, useRef, useState } from "react";
import Timer from "../utils/timer";

const useToggleDialog = () => {
  const timerClose = useRef(new Timer());
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  useEffect(() => {
    if (open) {
      setClose(true);
    } else {
      timerClose.current.debounce(() => {
        setClose(false);
      }, 500);
    }
  }, [open]);

  const toggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const shouldRender = open || close;

  return [open, toggle, shouldRender];
};

export default useToggleDialog;
