import { IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const propTypes = {
  scroll: PropTypes.oneOf(["paper", "body"]),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  open: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["", "xs", "sm", "md", "lg", "xl"]),
  fullWidth: PropTypes.bool,
  toggle: PropTypes.func,
  handleExited: PropTypes.func,
  hideClose: PropTypes.func,
  handleExit: PropTypes.func,
  handleEnter: PropTypes.func,
  styleDialogContent: PropTypes.object,
};

const useStyles = makeStyles({
  scrollPaper: {},
  root: {},

  closeIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paper: {
    minHeight: 800,
  },
});

const DialogCommon = ({
  scroll = "paper",
  header = null,
  body = null,
  footer = null,
  hideClose = false,
  open = true,
  maxWidth = "md",
  fullWidth = true,
  styleDialogContent = {},
  className = "",
  toggle = () => {},
  handleExited = () => {},
  handleExit = () => {},
  handleEnter = () => {},

  //* to custom wrapped, if you want enter to submit form, please put WrapContainer={Form}
  //* Form from formik
  WrapContainer = Fragment,
  propsWrapContainer = {},
}) => {
  const classes = useStyles();

  //! Function
  const handleClose = () => {
    toggle();
  };

  //! Render
  return (
    <Dialog
      classes={{ root: classes.root }}
      open={open}
      onClose={handleClose}
      onEnter={handleEnter}
      onExit={handleExit}
      onExited={handleExited}
      scroll={scroll}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      className={`${className} dialog-common`}
      TransitionComponent={Transition}
    >
      <WrapContainer {...propsWrapContainer}>
        {header && (
          <DialogTitle disableTypography className={`dialog-title ${classes.closeIcon}`} id="dialog-title">
            {header}
            {!hideClose && (
              <IconButton onClick={toggle}>
                <Close color="primary" />
              </IconButton>
            )}
          </DialogTitle>
        )}

        {body && (
          <DialogContent style={styleDialogContent} className="dialog-content" dividers={scroll === "paper"}>
            {body}
          </DialogContent>
        )}
        {footer && <DialogActions className="dialog-actions">{footer}</DialogActions>}
      </WrapContainer>
    </Dialog>
  );
};

DialogCommon.propTypes = propTypes;
export default DialogCommon;
