/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { MainWrapper } from "../src/components/CommonStyles";
import "./App.css";
import { AddToHomeScreen } from "./components/pwa/components/AddToHomeScreen";
import { AuthProvider } from "./contexts/auth/auth.provider";
import { LanguageProvider } from "./contexts/language/language.provider";
import { SnackbarProvider } from "./contexts/snackbar/snackbar.provider";
import BaseAlert from "./libraries/BaseAlert/BaseAlert";
import BaseAlertManager from "./libraries/BaseAlert/Manager";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/Routes";
// Language translation messages
import { messages } from "./site-settings/site-translation/messages";
import createTheme from "./theme";
import "../src/firebase-ultils/firebase-ultils";
import ScheduleOrder from "./features/schedule-order";
const App = () => {
  const refAlert = useRef();

  useEffect(() => {
    BaseAlertManager.register(refAlert.current);
    return () => {
      BaseAlertManager.unregister(refAlert.current);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet titleTemplate="Phúc Long" defaultTitle="Phúc Long" />
      <MuiThemeProvider theme={createTheme()}>
        <LanguageProvider messages={messages}>
          <SnackbarProvider>
            <AuthProvider>
              <Router>
                <BaseAlert ref={refAlert} />
                <AddToHomeScreen />
                <ScheduleOrder />
                <MainWrapper style={{ minHeight: `${window.innerHeight}` }}>
                  <div className="App">
                    <section>
                      <Routes />
                    </section>
                  </div>
                </MainWrapper>
              </Router>
            </AuthProvider>
          </SnackbarProvider>
        </LanguageProvider>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default App;
