import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { Component, Fragment } from "react";

const withSnackbar = (BaseComponent) => {
  return class ComponentWrapped extends Component {
    constructor(props) {
      super(props);
      this.props = props;
      this.state = {
        open: false,
        type: "success",
        vertical: "bottom",
        horizontal: "center",
        autoHideDuration: 3000,
      };

      this.showSnackbar = this.showSnackbar.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }

    showSnackbar({
      type = "success",
      message = "",
      autoHideDuration,
      vertical,
      horizontal,
    }) {
      this.setState({
        open: true,
        type,
        message,
        autoHideDuration,
        vertical,
        horizontal,
      });
    }

    handleClose(event, reason) {
      if (reason === "clickaway") {
        return;
      }

      this.setState({
        open: false,
      });
    }

    render() {
      return (
        <Fragment>
          <Snackbar
            className='snackbar-common'
            open={this.state.open}
            autoHideDuration={this.state.autoHideDuration || 3000}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: this.state.vertical || "bottom",
              horizontal: this.state.horizontal || "center",
            }}>
            <Alert
              style={{
                backgroundColor:
                  this.state.type === "success" ? "#4caf50" : "info",
              }}
              onClose={this.handleClose}
              variant='filled'
              severity={this.state.type}>
              {this.state.message}
            </Alert>
          </Snackbar>

          <BaseComponent {...this.props} showSnackbar={this.showSnackbar} />
        </Fragment>
      );
    }
  };
};

export default withSnackbar;
