export const PRIMARY_COLOR = "#176939";
export const LOCALSTORAGE_ACCESSS_TOKEN = "ACCESSS_TOKEN";
export const LOCALSTORAGE_USER_ID = "user_id";
export const LOCALSTORAGE_USERNAME = "user_name";
export const LOCALSTORAGE_USER_FULLNAME = "user_fullname";
export const LOCALSTORAGE_USER_PHONE = "user_phone";
export const LOCALSTORAGE_USER_EMAIL = "user_email";
export const LOCALSTORAGE_AUTHORIZE = "USER_AUTHORIZE";
export const LOCALSTORAGE_ISADMIN = "isAdmin";

export const STATUS = {
    active: 'A',
    inActive: 'I'
}

export const STATUS_TRANSPOSRT = {
    delivery: 'deliveryStatus',
    takeAway: 'takeAwayStatus'
}
