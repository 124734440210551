import { useState, useEffect } from "react";
import { getScheduleOrderNotification } from "../services/orderService";

const useGetScheduleOrderNoti = (filter) => {
  const [data, setData] = useState({
    isShow: false,
    title: "",
    message: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const reFetch = () => {
    setLoading(true);
    getScheduleOrderNotification()
      .then((res) => {
        setLoading(false);
        setData(res?.data?.data);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };
  // useEffect(() => {
  //   reFetch();
  // }, [filter]);
  return [data, loading, error, reFetch];
};
export default useGetScheduleOrderNoti;
