/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import DialogContent from "../../components/DialogContent";
import withLoadingScreen from "../../HOCs/withLoadingScreen";
import withSnackbar from "../../HOCs/withSnackbar";
import useGetScheduleOrderNoti from "../../hooks/useGetScheduleOrderNoti";
import useToggleDialog from "../../hooks/useToggleDialog";
import { showAlert } from "../../libraries/BaseAlert";
import { ContentWraper } from "../main/main.style";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
const BaseToastContainer = styled.div`
  position: fixed;
  z-index: 1000;
  color: white;
  flex-direction: row;
  padding: 10px;
  width: 50%;
  left: 30%;
  display: flex;
  top: ${(props) => (props.visible ? 10 : -300)}px;
  cursor: pointer;
  border-radius: 8px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity, top 0.5s;
  background: rgba(0, 111, 60, 0.8);
  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
    top: ${(props) => (props.visible ? 0 : -300)}px;
    border-radius: 0;
  }
`;
const ScheduleOrder = (props) => {
  //constant and state
  const history = useHistory();
  const [openConfirm, toggleConfirm, shouldRenderConfirm] = useToggleDialog();
  const [filter, setFilter] = useState({
    reCall: 1,
  });

  const [data, loading, error, refetch] = useGetScheduleOrderNoti(filter);

  //! Function
  const gotoOrderList = () => {
    toggleConfirm();
    history.push("/order?status=New&orderType=Schedule");
  };
  //! Effect
  useEffect(() => {
    if (data?.isShow) {
      toggleConfirm();
      // showAlert("");
    }
  }, [data]);
  useEffect(() => {
    setInterval(() => {
      setFilter({
        reCall: filter.reCall + 1,
      });
    }, 5 * 60 * 1000);
  }, []);
  return (
    <ContentWraper>
      {shouldRenderConfirm && (
        <BaseToastContainer visible={openConfirm}>
          <div onClick={gotoOrderList} style={{ flex: 1 }}>
            {data.message}
          </div>

          <Close onClick={toggleConfirm} style={{ color: "white", marginRight: 15 }} />
        </BaseToastContainer>
      )}
    </ContentWraper>
  );
};

export default withLoadingScreen(withSnackbar(ScheduleOrder));
