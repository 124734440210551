import React, { Fragment } from "react";
import DialogCommon from "../DialogCommon";
import { Typography, Button } from "../CommonStyles";
import { PRIMARY_COLOR } from "../../constants";
import styled from "styled-components/macro";

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: rgb(23, 105, 57);
  padding: 0px 24px;
`;
const propTypes = {};

const DialogContent = ({
  open = false,
  toggle = () => {},
  header = "Thông báo",
  content = "Nội dung",
  onClickSubmit = () => {},
  style = {},
  buttonText = "Đóng",
  maxWidth = "sm",
  className = "",
  showFooter = true,
}) => {
  //! Render
  return (
    <DialogCommon
      open={open}
      toggle={toggle}
      maxWidth={maxWidth}
      header={<Header>{header}</Header>}
      body={<Typography style={style}>{content}</Typography>}
      className={className}
      footer={
        <Fragment>
          {showFooter && (
            <Button variant="contained" onClick={onClickSubmit} style={{ backgroundColor: PRIMARY_COLOR }}>
              <span style={{ color: "#fff" }}>{buttonText}</span>
            </Button>
          )}
        </Fragment>
      }
    />
  );
};

DialogContent.propTypes = propTypes;
export default DialogContent;
