import axios from "../utils/axios";

export const removeToken = () => {
  const getTokenFCM = localStorage.getItem("FIREBASE_TOKEN");
  const url = "pn/api/v1/fcm/token";
  axios
    .put(url, { token: getTokenFCM })
    .then(() => {})
    .catch(() => {});
};
